/* eslint-disable no-unused-vars */
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import moment from 'moment'

export default function useHistoryList() {
  // Use toast
  const toast = useToast()

  const refHistoryListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'timestamp',
      label: 'DATETIME',
      sortable: true,
      tdClass: 'align-baseline',
    },
    {
      key: 'message',
      label: 'Message',
      tdClass: 'align-baseline',
    },
    /* {
      key: 'is_success',
      label: 'STATUS',
      tdClass: 'align-baseline',
    }, */
  ]

  const searchQuery = ref('')
  const isSuccessFilter = ref(null)
  const dateFilter = ref({})
  const sort = ref('timestamp')
  const sortDesc = ref(true)
  const page = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const totalCount = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refHistoryListTable.value ? refHistoryListTable.value.localItems.length : 0
    return {
      from: limit.value * (page.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (page.value - 1) + localItemsCount,
      of: totalCount.value,
    }
  })

  const refetchData = () => {
    refHistoryListTable.value.refresh()
  }

  watch([isSuccessFilter, dateFilter, sort, sortDesc, page, limit], () => {
    refetchData()
  }, {
    deep: true,
  })

  const fetchHistory = (ctx, callback) => {
    store
      .dispatch('app-history/fetchHistories', {
        q: searchQuery.value,
        is_success: (isSuccessFilter.value ? isSuccessFilter.value.value : isSuccessFilter.value),
        date: dateFilter.value,
        _sort: sort.value,
        _order: (sortDesc.value ? 'desc' : 'asc'),
        _page: page.value,
        _limit: limit.value,
      })
      .then(response => {
        const jsonObjectLiterals = response.data.response.data

        // console.log(jsonObjectLiterals)

        callback(jsonObjectLiterals.data)
        totalCount.value = jsonObjectLiterals.totalCount
      })
      .catch(error => {
        console.error(error)
        /* toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching table list.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        }) */
      })
  }

  const resolveStatusVariant = isSuccess => {
    if (isSuccess) return 'success'
    if (!isSuccess) return 'danger'
    return 'dark'
  }

  const resolveStatusCode = isSuccess => {
    if (isSuccess) return 'Success'
    if (!isSuccess) return 'Failed'
    return 'Unknown'
  }

  const resolveHistoryDetailsTimeSeconds = seconds => {
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    if (seconds < 60) return `${seconds} ${seconds > 1 ? 'seconds' : 'second'}`
    if (minutes < 60) return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`
    if (hours < 60) return `${hours} ${hours > 1 ? 'hours' : 'hour'}`
    return `${seconds} seconds`
  }

  const resolveHistoryDatetime = datetime => moment(datetime).format('MMM DD YYYY, hh:mm a')

  /* CONTINUE HERE */
  const resolveHistoryDetails = item => {
    const firstname = `${item.first_name[0].toUpperCase()}${item.first_name.slice(1)}`
    const lastname = `${item.last_name[0].toUpperCase()}${item.last_name.slice(1)}`
    let name = `${firstname} ${lastname}`
    name = `${name}${(item.role === 'admin') ? ' (HR/Admin)' : ''}`
    try {
      switch (item.name) {
        case 'form_update': {
          if (item.action === 'insert') {
            /* const change = []
            Object.entries(item.result[0].history_data).forEach(entry => {
              const [key, value] = entry
              if (key !== 'id') {
                const field = key.replace('_', ' ')
                change.push(`${field[0].toUpperCase() + field.substring(1)}: ${value}`)
              }
            })
            return `${name} requested ${(['a', 'e', 'i', 'o', 'u'].indexOf(item.result[0].label[0].toLowerCase()) !== -1) ? 'an' : 'a'} ${item.result[0].label} form update.${change.length > 0 ? `\n\tChange/s:\n\t\t${change.join('\n\t\t')}` : ''}` */
            // eslint-disable-next-line
            return `${name} requested ${(['a', 'e', 'i', 'o', 'u'].indexOf(item.result[0].label[0].toLowerCase()) !== -1) ? 'an' : 'a'} ${item.result[0].label} form update.`
          }
          if (item.action === 'update') {
            if (Number(item.result[0].status) === 2) {
              return `${name} approved the ${item.result[0].label} form update for ${item.result[0].caregiver_name}.`
            }
            if (Number(item.result[0].status) === 3) {
              return `${name} denied the ${item.result[0].label} form update for ${item.result[0].caregiver_name}.`
            }
          }
          break
        }
        case 'clock_inout': {
          const start = `${(item.result[0].start_date).split(' ')[0]} ${item.result[0].start_time}`
          const end = `${(item.result[0].end_date).split(' ')[0]} ${item.result[0].end_time}`
          if (Number(item.result[0].status) === 12) {
            const seconds = moment(item.result[0].clock_in).diff(start, 'seconds')
            return `${name} was ${resolveHistoryDetailsTimeSeconds(seconds)} late before clocking-in to ${moment(start).format('MMM DD, hh:mm a')} shift for ${item.result[0].client_name}.`
          }
          if (Number(item.result[0].status) === 13) {
            const seconds = moment(item.result[0].clock_out).diff(end, 'seconds')
            return `${name} was ${resolveHistoryDetailsTimeSeconds(seconds)} late before clocking-out to ${moment(end).format('MMM DD, hh:mm a')} shift for ${item.result[0].client_name}.`
          }
          if (Number(item.result[0].status) === 4) {
            return `${name} missed a clock-in on ${moment(start).format('MMM DD, hh:mm a')} shift for ${item.result[0].client_name}.`
          }
          if (Number(item.result[0].status) === 5) {
            return `${name} missed a clock-out on ${moment(end).format('MMM DD, hh:mm a')} shift for ${item.result[0].client_name}.`
          }
          if (Number(item.result[0].status) === 3) {
            return `${name} completed the ${moment(end).format('MMM DD, hh:mm a')} shift for ${item.result[0].client_name}.`
          }
          /* if (Number(item.result[0].status) === 2) {
            return `${name} successfully clocked-in on ${moment(end).format('MMM DD, hh:mm a')} shift for ${item.result[0].client_name}.`
          } */
          break
        }
        case 'shifts': {
          const start = `${(item.result[0].start_date).split(' ')[0]} ${item.result[0].start_time}`
          const end = `${(item.result[0].end_date).split(' ')[0]} ${item.result[0].end_time}`
          const shift = []
          if (item.result[0].caregiver_id > 0) {
            shift.push(`Caregiver: ${item.result[0].caregiver_name}`)
          }
          /* if (item.result[0].recurrence) {
            shift.push(`Start: ${moment(start).format('hh:mm a')}`)
            shift.push(`End: ${moment(end).format('hh:mm a')}`)
          } else {
            shift.push(`Start: ${moment(start).format('MMM DD, hh:mm a')}`)
            shift.push(`End: ${moment(end).format('MMM DD, hh:mm a')}`)
          }
          if (item.result[0].recurrence) {
            shift.push(`Recurrence: ${item.result[0].recurrence_pattern[0].toUpperCase() + item.result[0].recurrence_pattern.substring(1)}`)
            Object.entries(item.result[0].pattern_option).forEach(entry => {
              const [key, value] = entry
              if (key !== 'every') {
                let val = value
                if (Array.isArray(val)) {
                  val = value.map(word => word[0].toUpperCase() + word.substring(1))
                  val = val.join(', ')
                }
                shift.push(`${key[0].toUpperCase() + key.substring(1)}: ${val}`)
              }
            })
          }
          if (moment(item.result[0].repeat_until).isValid()) {
            shift.push(`Repeat Until: ${moment(item.result[0].repeat_until).format('MMM DD, hh:mm a')}`)
          } */
          const task = []
          Object.entries(item.result[0].shift_task).forEach(entry => {
            const [_, value] = entry
            const time = `${(start).split(' ')[0]} ${(value.start_time).split(' ')[1]}`
            task.push(`${value.task[0].toUpperCase() + value.task.substring(1)}: ${moment(time).format('hh:mm a')}`)
          })
          if (item.action === 'insert') {
            return `${name} added a shift for ${item.result[0].client_name}. ${item.result[0].message}.${shift.length > 0 ? `\n\t${shift.join('\n\t')}` : ''}${task.length > 0 ? `\n\tTask/s:\n\t\t${task.join('\n\t\t')}` : ''}`
          }
          if (item.action === 'update') {
            return `${name} updated a shift for ${item.result[0].client_name}. ${item.result[0].message}.${shift.length > 0 ? `\n\t${shift.join('\n\t')}` : ''}${task.length > 0 ? `\n\tTask/s:\n\t\t${task.join('\n\t\t')}` : ''}`
          }
          break
        }
        case 'cancelled_shifts': {
          const start = `${(item.result[0].start_date).split(' ')[0]} ${item.result[0].start_time}`
          const end = `${(item.result[0].end_date).split(' ')[0]} ${item.result[0].end_time}`
          if (item.action === 'insert') {
            if (item.role !== 'admin') {
              return `${name} cancelled their ${moment(start).format('MMM DD, hh:mm a')} to ${moment(end).format('MMM DD, hh:mm a')} shift.`
            }
            return `${name} cancelled the ${moment(start).format('MMM DD, hh:mm a')} to ${moment(end).format('MMM DD, hh:mm a')} shift for ${item.result[0].name}.`
          }
          break
        }
        case 'tasks': {
          const start = `${(item.result[0].start_date).split(' ')[0]} ${item.result[0].start_time}`
          const end = `${(item.result[0].end_date).split(' ')[0]} ${item.result[0].end_time}`
          if (item.role !== 'admin') {
            if (item.result[0].status === 'completed') {
              return `${name} completed the "${item.result[0].description}" task for ${item.result[0].client_name}.`
            }
            if (item.result[0].status === 'not-completed') {
              return `${name} did not completed the "${item.result[0].description}" task for ${item.result[0].client_name}.`
            }
            if (item.result[0].status === 'wont-complete') {
              return `${name} would not complete the "${item.result[0].description}" task for ${item.result[0].client_name}.`
            }
          }
          if (item.action === 'insert') {
            return `${name} added a "${item.result[0].description}" task to ${moment(start).format('MMM DD, hh:mm a')} to ${moment(end).format('MMM DD, hh:mm a')} shift for ${item.result[0].client_name}.`
          }
          if (item.action === 'update') {
            /* const change = []
            Object.entries(item.data).forEach(entry => {
              const [key, value] = entry
              if (key !== 'id') {
                const field = key.replace('_', ' ')
                change.push(`${field[0].toUpperCase() + field.substring(1)}: ${value}`)
              }
            })
            return `${name} updated a "${item.result[0].description}" task from ${moment(start).format('MMM DD, hh:mm a')} to ${moment(end).format('MMM DD, hh:mm a')} shift for ${item.result[0].client_name}.${change.length > 0 ? `\n\tChange/s:\n\t\t${change.join('\n\t\t')}` : ''}` */
            return `${name} updated a "${item.result[0].description}" task from ${moment(start).format('MMM DD, hh:mm a')} to ${moment(end).format('MMM DD, hh:mm a')} shift for ${item.result[0].client_name}.`
          }
          break
        }
        case 'user': {
          if (item.action === 'insert') {
            if (item.result[0].type === 'admin') {
              return `${name} added a user ${item.result[0].name} (admin).`
            }
            if (item.result[0].type === 'family') {
              return `${name} added a family member ${item.result[0].name} to ${item.result[0].client_name}.`
            }
            return `${name} added a ${item.result[0].type} ${item.result[0].name}.`
          }
          break
        }
        case 'family_members': {
          if (item.action === 'delete') {
            return `${name} removed a family member ${item.data.firstname} ${item.data.lastname} for ${item.result[0].client_name}.`
          }
          break
        }
        default:
          break
      }
    } catch (err) {
      console.log(err)
    }
    return item
  }

  return {
    refHistoryListTable,
    tableColumns,
    searchQuery,
    isSuccessFilter,
    dateFilter,
    sort,
    sortDesc,
    page,
    limit,
    limitOptions,
    totalCount,
    dataMeta,

    refetchData,

    fetchHistory,

    resolveStatusVariant,
    resolveStatusCode,
    resolveHistoryDatetime,
    resolveHistoryDetails,
  }
}
