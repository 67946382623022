<template>
  <section id="history">
    <!-- <h1>{{ pageTitle }} </h1>
    <br> -->
    <b-row class="match-height">
      <b-col
        cols="12"
      >
        <history-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import HistoryList from '@/views/apps/history/history-list/HistoryList.vue'

export default {
  components: {
    BRow,
    BCol,

    HistoryList,
  },
  data() {
    return {
      pageTitle: 'History',
    }
  },
}
</script>

<style>
h1{
  margin: auto;
}
</style>
