import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  actions: {
    fetchHistories(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'history/fetchHistories', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
