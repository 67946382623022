<template>

  <!-- Card -->
  <b-card>

    <b-row>
      <b-col
        cols="12"
        md="8"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >

        <b-card-title class="mb-0 mb-md-2 d-none">
          History
        </b-card-title>

        <!-- Input Entries -->
        <label class="mr-1">Entries</label>
        <v-select
          v-model="limit"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="limitOptions"
          taggable
          push-tags
          :clearable="false"
          class="history-limit-select mr-1"
        />

        <!-- Flat Pickr Date From -->
        <b-form-group
          class="history-flat-pickr m-0 mr-1"
        >
          <flat-pickr
            id="date-from"
            v-model="dateFilter.from"
            class="form-control"
            :config="flatPickrConfig"
            placeholder="From date"
            title="From date"
          />
        </b-form-group>

        <!-- Flat Pickr Date To -->
        <b-form-group
          class="history-flat-pickr m-0 mr-1"
        >
          <flat-pickr
            id="date-to"
            v-model="dateFilter.to"
            class="form-control"
            :config="{ dateFormat: 'Y-m-d', minDate: dateFilter.from }"
            placeholder="To date"
            title="To date"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="d-flex align-items-center justify-content-end"
      >

        <!-- Input Search -->
        <b-input-group
          class="input-group-merge mr-1"
        >
          <b-form-input
            v-model="searchQuery"
            type="search"
            placeholder="Search"
            @input="searchHistoryList"
          />
          <b-input-group-append is-text>
            <feather-icon
              icon="SearchIcon"
              class="text-muted"
            />
          </b-input-group-append>
        </b-input-group>

        <!-- Select Status -->
        <!-- <v-select
          v-model="isSuccessFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="isSuccessOptions"
          :clearable="true"
          class="history-status-filter-select"
          placeholder="Select Status"
        >

          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>

        </v-select> -->

      </b-col>
    </b-row>

    <!-- Table -->
    <b-table
      ref="refHistoryListTable"
      :fields="tableColumns"
      :items="fetchHistory"
      primary-key="id"
      :sort-by.sync="sort"
      :sort-desc.sync="sortDesc"
      show-empty
      empty-text="No matching records found"
      responsive
      class="history-list-table position-relative mt-1 mb-2"
    >
      <!-- Column Timestamp -->
      <template #cell(timestamp)="data">
        <span class="text-nowrap">
          {{ resolveHistoryDatetime(data.value) }}
        </span>
      </template>

      <!-- Column Message -->
      <template #cell(message)="data">
        <span class="ws-pre-wrap">
          {{ resolveHistoryDetails(data.item) }}
        </span>
      </template>

      <!-- Column Status -->
      <template #cell(is_success)="data">
        <span
          class="text-nowrap"
          :class="`text-${resolveStatusVariant(data.value)}`"
        >
          {{ resolveStatusCode(data.value) }}
        </span>
      </template>
    </b-table>

    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-sm-0"
      >

        <!-- Display Entries -->
        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>

      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >

        <!-- Pagination -->
        <b-pagination
          v-model="page"
          :total-rows="totalCount"
          :per-page="limit"
          first-number
          last-number
          class="mb-0"
          prev-class="prev-item"
          next-class="next-item"
        >

          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>

          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>

        </b-pagination>

      </b-col>
    </b-row>

    <button
      class="mt-1 d-none"
      @click="sendSockMsg(true)"
    >
      Test
    </button>

  </b-card>

</template>

<script>
import SockJS from 'sockjs-client'
import {
  BCard, BCardTitle, BRow, BCol, BInputGroup, BInputGroupAppend, BFormGroup, BFormInput, BTable, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useHistoryList from './useHistoryList'

import historyStoreModule from '../historyStoreModule'

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormInput,
    BTable,
    BPagination,

    vSelect,
    flatPickr,
  },
  setup() {
    const HISTORY_APP_STORE_MODULE_NAME = 'app-history'

    // Register module
    if (!store.hasModule(HISTORY_APP_STORE_MODULE_NAME)) store.registerModule(HISTORY_APP_STORE_MODULE_NAME, historyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HISTORY_APP_STORE_MODULE_NAME)) store.unregisterModule(HISTORY_APP_STORE_MODULE_NAME)
    })

    const isSuccessOptions = [
      { label: 'Success', value: true },
      { label: 'Failed', value: false },
    ]

    const flatPickrConfig = {
      // wrap: true, // set wrap to true only when using 'input-group'
      // altFormat: 'm/d/Y',
      // altInput: true,
      dateFormat: 'Y-m-d',
    }

    const {
      refHistoryListTable,
      tableColumns,
      searchQuery,
      isSuccessFilter,
      dateFilter,
      sort,
      sortDesc,
      page,
      limit,
      limitOptions,
      totalCount,
      dataMeta,

      refetchData,

      fetchHistory,

      resolveStatusVariant,
      resolveStatusCode,
      resolveHistoryDatetime,
      resolveHistoryDetails,
    } = useHistoryList()

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-bh01'

    return {
      refHistoryListTable,
      tableColumns,
      searchQuery,
      isSuccessFilter,
      dateFilter,
      sort,
      sortDesc,
      page,
      limit,
      limitOptions,
      totalCount,
      dataMeta,

      refetchData,

      fetchHistory,

      resolveStatusVariant,
      resolveStatusCode,
      resolveHistoryDatetime,
      resolveHistoryDetails,

      isSuccessOptions,
      flatPickrConfig,

      sock,
      sockId,
    }
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }

    setInterval(() => {
      const today = new Date()

      if (today.getMinutes() % 15 === 0 && today.getSeconds() === 59) {
        // console.log(today.getHours(), today.getMinutes(), today.getSeconds())
        this.refetchData()
      }
    }, 1500)
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        this.refetchData()
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    searchHistoryList() {
      const self = this

      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        self.refetchData()
      }, 1500)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.history-limit-select {
  width: 90px;
}

.history-flat-pickr {
  max-width: 125px;
}

.history-status-filter-select {
  /* width: 100%; */
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
/*
@media (max-width: 575.98px) {
  .history-status-filter-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 100%;
    }
  }
}
*/

.history-list-table {
  ::v-deep .table > tbody > tr:first-child > td {
    border: none;
  }
}

.ws-pre-wrap {
  white-space: pre-wrap;
  color: #001c24 !important;
}
</style>
